import React, { useState } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import axios_instance from "store/constant/axiosInstance";
import swal from "sweetalert";
import { extractErrorMessage } from "utils/strings";


export const ExportModal = ({
  showModal,
  setShowExportModal,
  exportFilter,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [exporting, setExporting] = useState(false);

  const ExportOptions = [
    { value: "", label: "Select Export" },
    { value: "profile_data", label: "Fellows' Profile Data" },
    { value: "attendance_data", label: "Fellows' Attendance Data" },
    { value: "assessment_data", label: "Fellows' Assessment Data" },
  ];

  const showErrorModal = (error) => {
    const formattedError = extractErrorMessage(error)

    swal({
      title: "Error",
      text: formattedError,
      buttons: {
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          className: "btn btn-danger btn-block",
          closeModal: true,
        },
      },
      icon: "error",
    });
  };

  const showSuccessModal = (message) => {
    swal({
      title: "Success",
      text: message,
      buttons: {
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          className: "btn btn-success btn-block",
          closeModal: true,
        },
      },
      icon: "success",
    });
  };

  const handleSubmit = async () => {
    if (!selectedOption) return;
    setExporting(true);

    if (selectedOption.value === "profile_data") {
      try {
        const response = await axios_instance.get(`admin/export/fellows?${exportFilter}`);
        console.log("Export result:", response);
        showSuccessModal(response?.data?.message);
      } catch (error) {
        console.error("Profile data export error:", error);
        showErrorModal(error);
      } finally {
        setExporting(false);
      }
    } else if (selectedOption.value === "attendance_data") {
      try {
        const response = await axios_instance.get(`admin/export/fellows/attendance?${exportFilter}`);
        console.log("Export result:", response);
        showSuccessModal(response?.data?.message);
      } catch (error) {
        console.error("Attendance data export error:", error);
        showErrorModal(error);
      } finally {
        setExporting(false);
      }
    } else if (selectedOption.value === "assessment_data") {
      try {
        const response = await axios_instance.get(`admin/export/fellows/assessment?${exportFilter}`);
        console.log("Export result:", response);
        showSuccessModal(response?.data?.message);
      } catch (error) {
        console.error("Assessment data export error:", error);
        showErrorModal(error);
      } finally {
        setExporting(false);
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowExportModal(false)}
      className="modal fade"
      id="postModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Export Fellows' Data</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowExportModal(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="basic-form">
            <form onSubmit={(e) => e.preventDefault()}>
              <Col md={12}>
                <p className="p-1 m-0">Choose data to export</p>
                <div className="form-group mb-3">
                  <Select
                    value={selectedOption}
                    onChange={setSelectedOption}
                    options={ExportOptions}
                    styles={Style}
                  />
                </div>
              </Col>
            </form>
          </div>

          <div className="d-flex w-100 justify-content-center align-items-center">
            <button
              type="button"
              onClick={handleSubmit}
              disabled={exporting || !selectedOption}
              className="btn btn-pigment-green ms-2"
            >
              {exporting ? "Processing ...." : "Export Data"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExportModal;