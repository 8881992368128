/** @format */

export const Path = {
  LOGIN: "/login",
  ONBOARD_USER: "/accept_invitation_page",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_FELLOWS: "/admin/fellows",
  ADMIN_PROVIDERS: "/admin/providers",
  ADMIN_BROADCAST: "/admin/broadcasts",
  ADMIN_NOTIFICATIONS: "/admin/notifications",
  ADMIN_ORGANISATIONS: "/admin/organisations",
  ADMIN_PARTNERS: "/admin/partners",
  ADMIN_USER_MANAGEMENT: "/admin/users",
  ADMIN_FORUM: "/admin/forum",
  ADMIN_ROOMS: "/admin/forum/:activeType",
  ADMIN_FORUM_ROOM: "/admin/forum/:activeType/:roomID",
  ADMIN_WEBINAR: "/admin/webinars",
  ADMIN_EMAILS: "/admin/emails",
  ADMIN_SEND_EMAIL: "/admin/emails/send",
  ADMIN_FAQS:"/admin/faqs",
  ADMIN_COHORTS: "/admin/cohorts",
  ADMIN_LEARNING_TRACKER: "/admin/tracker",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  ADMIN_USERS: "/admin/users",
  ADMIN_ROLES: "/admin/roles-permisssions",
  ADMIN_FELLOW_ANALYTICS: "/admin/fellow-analytics",
  ADMIN_PROVIDER_ANALYTICS: "/admin/provider-analytics",
  ADMIN_ASSESSMENTS_ANALYTICS: "/admin/assessment-analytics",
  ADMIN_ATTENDANCE_ANALYTICS: "/admin/attendance-analytics",
  ADMIN_COMMUNITY_RESOURCES: "/admin/community-resources",
  ADMIN_LEARNING_CONTENT: "/admin/learning-content",
  ADMIN_COURSE_MODULES :"/admin/course-modules",
  ADMIN_ALL_COURSES: "/admin/all-courses",
  ADMIN_JOBS: "/admin/jobs",
  ADMIN_SURVEYS: "/admin/surveys",
  ADMIN_ASSESMENTS: "/admin/assesments",
  ADMIN_CERTIFICATES: "/admin/certificates",
  ADMIN_MATCHING: "/admin/matching",
  ADMIN_MATCHING_REPORTS: "/admin/matching/reports"


};

export const Page = {
  DASHBAORD: "dashboard",
  PROVIDERS: "provider_page",
  EXPORT: "export_feature",
  FELLOWS: "fellows_page",
  BROADCAST: "broadcast_page",
  NOTIFICATION: "notifications_page",
  ASSESMENT: "assessment_page",
  EMAIL: "emails_page",
  FAQ: "faq_page",
  FORUM: "forum_page",
  WEBINAR: "webinar_page",
  PARTNER: "partners_page",
  PROFILE: "profile_page",
  ROLES: "role_page",
  USERS: "user_page",
  COHORTS: "cohort_page",
  LEARNING_TRACKER: "learning_tracker_page",
  COURSES:"courses_page",
  JOBS:"job_page_link",
  // SURVEYS: "survey_page_link"
  SURVEYS: "job_page_link"

};

export const Checks = {
  update_fellow_status: "can_update_fellow_status",
  export_fellows: "can_export_fellows",
  create_cohort: "can_create_cohort",
  update_cohort: "can_update_cohort",
  export_providers: "can_export_providers",
  update_provider_status: "can_update_provider_status",
  update_fellow_profile: "can_update_fellow_profile",
  update_provider_profile: "can_update_provider_profile",
  update_update_fellow_email: "can_update_fellow_email",
  update_update_provider_email: "can_update_provider_email",
  reassign_fellow_provider: "can_reassign_fellow_provider",
  reassign_fellow_cohort:"can_reassign_fellow_cohort",

  can_upload_fellows_data: "can_upload_fellows_data",
  courses_page: "courses_page",
  can_add_course: "can_add_course",
  can_update_course: "can_update_course",
  can_delete_course: "can_delete_course",

  can_add_community_resource: "can_add_community_resource",
  can_update_community_resource: "can_update_community_resource",
  can_delete_community_resource: "can_delete_community_resource",
  can_update_community_resource_status: "can_update_community_resource_status",
  
  can_add_online_learning_content: "can_add_online_learning_content",
  can_update_online_learning_content: "can_update_online_learning_content",
  can_view_online_learning_content: "can_view_online_learning_content",
  can_update_online_learning_content_status: "can_update_online_learning_content_status",
  can_delete_online_learning_content: "can_delete_online_learning_content",

  job_page_link:"job_page_link",
  can_view_job_page_link:"can_view_job_page_link",
  can_create_job_page_link:"can_create_job_page_link",
  can_update_job_page_link:"can_update_job_page_link",
  can_delete_job_page_link:"can_delete_job_page_link",

  // survey_page: "survey_page",
  // can_view_survey_page_link: "can_view_survey_page_link",
  // can_update_survey: "can_update_survey",
  // can_create_survey: "can_create_survey",
  // can_delete_survey: "can_delete_survey"


  survey_page: "survey_page",
  can_view_survey_page_link: "can_view_job_page_link",
  can_update_survey: "can_update_job_page_link",
  can_create_survey: "can_create_job_page_link",
  can_delete_survey: "can_delete_job_page_link",


  can_create_course_module:"can_create_course_module",
  can_update_course_module:"can_update_course_module",
  can_view_course_module:"can_view_course_module",
  can_update_course_module_status:"can_update_course_module_status",

  can_create_assessment:"can_create_assessment",
  can_view_assessment: "can_view_assessment",
  can_update_assessment: "can_update_assessment",
  can_delete_assessment: "can_delete_assessment",
  can_view_assessment_submissions:"can_view_assessment_submissions",

  can_update_fellow_course: "can_update_fellow_course"
};

export const providerStatus = [
  { value: "Selected", label: "Selected" },
  { value: "application_incomplete", label: "Application incomplete" },
  { value: "application_completed", label: "Application completed" },
  { value: "application_approved", label: "Application approved" },
  { value: "New", label: "New" },
  { value: "shortlisted", label: "Shortlisted" },
  { value: "blacklisted", label: "Blacklisted" },
];

export const fellowNotificationStatus = [
  { value: "Selected", label: "Selected" },
  { value: "application_completed", label: "Application Completed" },
  { value: "New", label: "New" },
  { value: "Inactive", label: "Inactive" },
  { value: "incomplete_alc_training", label: "Incomplete Alc Training "},
  { value: "completed_alc_training", label: "Completed Alc Training" },
  { value: "Certified", label: "Certified" },
  { value: "Employed", label: "Employed" },
];


export const fellowStatus = [
  { value: "Selected", label: "Selected" },
  { value: "application_completed", label: "Application completed" },
  { value: "New", label: "New" },
];

export const SkillLevel = [
  { value: "Novice", label: "Novice" },
  { value: "Beginner", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
  
];

export const SkillLevels = [
  "Beginner level (Zero 0 or less than 1year experience)",
  "Intermediate level (1 to 4 years experience)",
  "Advanced level (4+ years of experience)",
];

export const Nigerian_States = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Federal Capital Territory",
];

export const EducationData = [
  "Primary school leaving certificate",
  "Senior Secondary Certificate Exam (SSCE)",
  "Ordinary National Diploma (OND)",
  "Higher National Diploma (HND)",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctor of philosophy (PhD)",
  "No Education",
];

export const CourseInterest = [
  "AI/ML",
  "Data Science",
  "Software Development",
  "Animation",
  "Cloud Computing",
  "DevOps",
  "UI/UX",
  "Cyber Security",
  "Game Development",
  "Data Analysis & Visualization",
  "Product Management",
  "Quality Assurance",
];

export const CourseInterestLower = [
  "ai/ml",
  "data-science",
  "software-development",
  "animation",
  "cloud-computing",
  "devops",
  "ui-ux",
  "cyber-security",
  "game-development",
  "data-analysis-&-visualization",
  "product-management",
  "quality-assurance",
];

export const CourseSummary = [
  "AI/ML: Training programmes that focus on the art of teaching computers how to create systems that can learn and improve from experience, advancing human intelligence.",
  "Data Science: Training programmes that teach how to use statistical techniques and algorithms to extract valuable insights from large volumes of data, helping businesses make informed decisions.",
  "Software Development: Training programmes that build expertise in the creation and engineering of computer programs, from understanding user needs, writing code and continuous software engineering.",
  "Animation: Training programmes that teach the art of bringing characters and stories to life through motion, using a variety of digital tools and techniques.",
  "Cloud Computing: Training programmes that teach how to harness the power of remote servers over the internet to store, manage, and process data, making technology operations of enterprises more flexible and efficient.",
  "DevOps: Training programmes that build expertise in streamlining the software development process by fostering collaboration between the development and operations teams, leading to faster and more reliable software releases.",
  "UI/UX: Training programmes that build expertise in the design of intuitive and engaging digital interfaces that provide a seamless user experience, making technology easy and enjoyable to use.",
  "Cyber Security: Training programmes that build expertise in protecting computer systems and networks from threats, ensuring the safety of important data and maintaining the trust of users.",
  "Game Development: Training programmes that build expertise in the creation of engaging video games, from designing characters and environments to programming gameplay mechanics.",
  "Data Analysis & Visualization: Training programmes that build expertise in interpreting complex data, draw meaningful insights and present them visually in a way that's easy for anyone to understand.",
  "Product Management: Training programmes that build expertise in overseeing the development of a product, from idea generation to market launch and growth, ensuring it meets customer needs and business goals.",
  "Quality Assurance: Training programmes that build expertise in ensuring the highest standards in software development, by testing and refining products to make sure they work flawlessly and meet user needs.",
];

export const LocalGov = [
  {
    state: "Abia",
    lgas: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwato",
      "Obi Nwa",
      "Ohafia",
      "Osisioma",
      "Ngwa",
      "Ugwunagbo",
      "Ukwa East",
      "Ukwa West",
      "Umuahia North",
      "Umuahia South",
      "Umu-Neochi",
    ],
  },
  {
    state: "Adamawa",
    lgas: [
      "Demsa",
      "Fufore",
      "Ganaye",
      "Gireri",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi North",
      "Mubi South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    state: "Anambra",
    lgas: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili North",
      "Idemili south",
      "Ihiala",
      "Njikoka",
      "Nnewi North",
      "Nnewi South",
      "Ogbaru",
      "Onitsha North",
      "Onitsha South",
      "Orumba North",
      "Orumba South",
      "Oyi",
    ],
  },
  {
    state: "Akwa Ibom",
    lgas: [
      "Abak",
      "Eastern Obolo",
      "Eket",
      "Esit Eket",
      "Essien Udim",
      "Etim Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo Asutan",
      "Ibiono Ibom",
      "Ika",
      "Ikono",
      "Ikot Abasi",
      "Ikot Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat Enin",
      "Nsit Atai",
      "Nsit Ibom",
      "Nsit Ubium",
      "Obot Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung Uko",
      "Ukanafun",
      "Uruan",
      "Urue-Offong/Oruko ",
      "Uyo",
    ],
  },
  {
    state: "Bauchi",
    lgas: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },
  {
    state: "Bayelsa",
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern Jaw",
      "Yenegoa",
    ],
  },
  {
    state: "Benue",
    lgas: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer East",
      "Gwer West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Obi",
      "Ogbadibo",
      "Oju",
      "Okpokwu",
      "Ohimini",
      "Oturkpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    state: "Borno",
    lgas: [
      "Abadam",
      "Askira/Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    state: "Cross River",
    lgas: [
      "Akpabuyo",
      "Odukpani",
      "Akamkpa",
      "Biase",
      "Abi",
      "Ikom",
      "Yarkur",
      "Odubra",
      "Boki",
      "Ogoja",
      "Yala",
      "Obanliku",
      "Obudu",
      "Calabar South",
      "Etung",
      "Bekwara",
      "Bakassi",
      "Calabar Municipality",
    ],
  },
  {
    state: "Delta",
    lgas: [
      "Oshimili",
      "Aniocha",
      "Aniocha South",
      "Ika South",
      "Ika North-East",
      "Ndokwa West",
      "Ndokwa East",
      "Isoko south",
      "Isoko North",
      "Bomadi",
      "Burutu",
      "Ughelli South",
      "Ughelli North",
      "Ethiope West",
      "Ethiope East",
      "Sapele",
      "Okpe",
      "Warri North",
      "Warri South",
      "Uvwie",
      "Udu",
      "Warri Central",
      "Ukwani",
      "Oshimili North",
      "Patani",
    ],
  },
  {
    state: "Ebonyi",
    lgas: [
      "Edda",
      "Afikpo",
      "Onicha",
      "Ohaozara",
      "Abakaliki",
      "Ishielu",
      "lkwo",
      "Ezza",
      "Ezza South",
      "Ohaukwu",
      "Ebonyi",
      "Ivo",
    ],
  },
  {
    state: "Enugu",
    lgas: [
      "Enugu South,",
      "Igbo-Eze South",
      "Enugu North",
      "Nkanu",
      "Udi Agwu",
      "Oji-River",
      "Ezeagu",
      "IgboEze North",
      "Isi-Uzo",
      "Nsukka",
      "Igbo-Ekiti",
      "Uzo-Uwani",
      "Enugu Eas",
      "Aninri",
      "Nkanu East",
      "Udenu.",
    ],
  },
  {
    state: "Edo",
    lgas: [
      "Esan North-East",
      "Esan Central",
      "Esan West",
      "Egor",
      "Ukpoba",
      "Central",
      "Etsako Central",
      "Igueben",
      "Oredo",
      "Ovia SouthWest",
      "Ovia South-East",
      "Orhionwon",
      "Uhunmwonde",
      "Etsako East",
      "Esan South-East",
    ],
  },
  {
    state: "Ekiti",
    lgas: [
      "Ado",
      "Ekiti-East",
      "Ekiti-West",
      "Emure/Ise/Orun",
      "Ekiti South-West",
      "Ikere",
      "Irepodun",
      "Ijero,",
      "Ido/Osi",
      "Oye",
      "Ikole",
      "Moba",
      "Gbonyin",
      "Efon",
      "Ise/Orun",
      "Ilejemeje.",
    ],
  },
  {
    state: "Federal Capital Territory",
    lgas: ["Abaji", "Abuja Municipal", "Bwari", "Gwagwalada", "Kuje", "Kwali"],
  },
  {
    state: "Gombe",
    lgas: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Kaltungo",
      "Kwami",
      "Shomgom",
      "Funakaye",
      "Gombe",
      "Nafada/Bajoga",
      "Yamaltu/Delta.",
    ],
  },
  {
    state: "Imo",
    lgas: [
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato North",
      "Ideato South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala Mbano",
      "Isu",
      "Mbaitoli",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nwangele",
      "Nkwerre",
      "Obowo",
      "Oguta",
      "Ohaji/Egbema",
      "Okigwe",
      "Orlu",
      "Orsu",
      "Oru East",
      "Oru West",
      "Owerri-Municipal",
      "Owerri North",
      "Owerri West",
    ],
  },
  {
    state: "Jigawa",
    lgas: [
      "Auyo",
      "Babura",
      "Birni Kudu",
      "Biriniwa",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin Hausa",
      "Kaugama Kazaure",
      "Kaugama",
      "Kazaure",
      "Kiri Kasamma",
      "Kiyawa",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    state: "Kaduna",
    lgas: [
      "Birni-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "jaba",
      "Jema'a",
      "Kachia",
      "Kaduna North",
      "Kaduna South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zango-Kataf",
      "Zaria",
    ],
  },
  {
    state: "Kano",
    lgas: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin Kudu",
      "Dawakin Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garum",
      "Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "kumbotso",
      "Ghari",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takali",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    state: "Katsina",
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dandume",
      "Danja",
      "Dan Musa",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai'Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazuu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    state: "Kebbi",
    lgas: [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko/Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
  },
  {
    state: "Kogi",
    lgas: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Bassa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Mangongo",
      "Okehi",
      "Okene",
      "Olamabolo",
      "Omala",
      "Yagba East",
      "Yagba West",
    ],
  },
  {
    state: "Kwara",
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti",
      "Ifelodun",
      "Ilorin East",
      "Ilorin South",
      "Ilorin West",
      "Irepodun",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    state: "Lagos",
    lgas: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju/Lekki",
      "Ifako-Ijaye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos Island",
      "Lagos Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
    ],
  },
  {
    state: "Nasarawa",
    lgas: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Toto",
      "Wamba",
    ],
  },
  {
    state: "Niger",
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Muya",
      "Pailoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    state: "Ogun",
    lgas: [
      "Abeokuta North",
      "Abeokuta South",
      "Ado-Odo/Ota",
      "Yewa North",
      "Yewa South",
      "Ewekoro",
      "Ifo",
      "Ijebu East",
      "Ijebu North",
      "Ijebu North East",
      "Ijebu Ode",
      "Ikenne",
      "Imeko-Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Ogun Waterside",
      "Odeda",
      "Odogbolu",
      "Remo North",
      "Shagamu",
    ],
  },
  {
    state: "Ondo",
    lgas: [
      "Akoko North East",
      "Akoko North West",
      "Akoko South East",
      "Akoko South West",
      "Akure North",
      "Akure South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji",
      "Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo East",
      "Ondo West",
      "Ose",
      "Owo",
    ],
  },
  {
    state: "Osun",
    lgas: [
      "Aiyedade",
      "Aiyedire",
      "Atakumosa East",
      "Atakumosa West",
      "Boluwaduro",
      "Boripe",
      "Ede North",
      "Ede South",
      "Egbedore",
      "Ejigbo",
      "Ife Central",
      "Ife East",
      "Ife North",
      "Ife South",
      "Ifedayo",
      "Ifelodun",
      "Ila",
      "Ilesha East",
      "Ilesha West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola-Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    state: "Oyo",
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan Central",
      "Ibadan North",
      "Ibadan North East",
      "Ibadan North West",
      "Ibadan South East",
      "Ibadan South West",
      "Ibarapa Central",
      "Ibarapa East",
      "Ibarapa North",
      "Ido",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogbomosho North",
      "Ogbomosho South",
      "Ogo Oluwa",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori Ire",
      "Oyo East",
      "Oyo West",
      "Saki East",
      "Saki West",
      "Surulere",
    ],
  },
  {
    state: "Plateau",
    lgas: [
      "Barikin Ladi",
      "Bassa",
      "Bokkos",
      "Jos East",
      "Jos North",
      "Jos South",
      "Kanam",
      "Kanke",
      "Langtang North",
      "Langtang South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    state: "Rivers",
    lgas: [
      "Abua/Odual",
      "Ahoada East",
      "Ahoada West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Emohua",
      "Eleme",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio/Akpor",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omumma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai",
    ],
  },
  {
    state: "Sokoto",
    lgas: [
      "Binji",
      "Bodinga",
      "Dange-shnsi",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gawabawa",
      "Illela",
      "Isa",
      "Kware",
      "kebbe",
      "Rabah",
      "Sabon birni",
      "Shagari",
      "Silame",
      "Sokoto North",
      "Sokoto South",
      "Tambuwal",
      "Tqngaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    state: "Taraba",
    lgas: [
      "Ardo-kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Cassol",
      "Ibi",
      "Jalingo",
      "Karin-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    state: "Yobe",
    lgas: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Karawa",
      "Machina",
      "Nangere",
      "Nguru Potiskum",
      "Nguru",
      "Potiskum",
      "Tarmua",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    state: "Zamfara",
    lgas: [
      "Anka",
      "Bakura",
      "Birnin Magaji",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Kaura",
      "Namoda",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];

export const No_of_staffs = [
  "Self-employed (1-person)",
  "1-10 employees",
  "11-50 employees",
  "51-200 employees",
  "201-500 employees",
  "501-1000 employees",
  "1001-5000 employees",
  "5001-10,000 employees",
  "10,000+ employees",
];

export const Trainees = [
  "1-5 trainees",
  "6-10 trainees",
  "11-20 trainees",
  "21-30 trainees",
  "31-50 trainees",
  "51-70 trainees",
  "71-90 trainees",
  "91-100 trainees",
  "100+ trainees",
  "Other",
];

export const Courses_Offered = [
  "AI/ML",
  "Data Science",
  "Software Development",
  "Animation",
  "Cloud Computing",
  "DevOps",
  "UI/UX",
  "Cyber Security",
  "Game Development",
  "Data Analysis & Visualization",
  "Product Management",
  "Quality Assurance",
];

export const OrganisationTypes = [
  "Government-Academic-Institution",
  "Non-Academic-Institution",
  "Others",
];

export const level_one = [
  "name",
  "state",
  "lga",
  "address",
  "organisation_type",
  "social_media",
  "poc_fullname",
  "poc_email",
  "poc_phone",
];

export const level_two = [
  "training_period",
  "staff_count",
  "track_record_of_previous_training",
  "accreditation_certification",
];

export const level_three = [
  "courses",
  "trainee_capacity_track_record",
  "courses_track_record",
  "courses_overview",
  "courses_link",
];

export const level_four = ["core_team_and_skill_set", "professional_profiles"];

export const level_five = [
  // "infrastructure_proof",
  // "infrastructure_proof_partner",
  // "trainee_capacity",
  "infrastructure_physical_proof",
  "infrastructure_technological_proof",
];

export const level_six = [
  "existing_placement_plan",
  "plan_facilitating_job_placements",
  "partnerships_for_job_placements",
];

export const level_seven = [
  "monitoring_and_evaluation",
  "approach_to_assessing_skill",
  "tools_and_method",
];

export const recipients = ["fellow", "provider"];


export const skills = ["Technical", "Non-technical"];
export const levels = ["Beginner", "Intermediate", 'Advanced', "Exprt"];
export const weeks = Array.from({ length: 12 }, (_, i) => ({
  value: i + 1,
  label: `Week ${i + 1}`
}));


export const weekNumbers = [
  {value: 1, label: "1"},
  {value: 2, label: "2"},
  {value: 3, label: "3"},
  {value: 4, label: "4"},
  {value: 5, label: "5"},
  {value: 6, label: "6"},
  {value: 7, label: "7"},
  {value: 8, label: "8"},
  {value: 9, label: "9"},
  {value: 10, label: "10"},
  {value: 11, label: "11"},
  {value: 12, label: "12"}
]