/** @format */

import React, { useState, useEffect } from "react";
import AdminLayout from "views/admin/Admin";
import { useGetFellowsQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import FellowTable from "views/admin/table/Fellows/FellowTable";
import { Page } from "utils/constant.data";
import usePermissionCheck from "hooks/useCheck";
import { useLocation, useNavigate } from "react-router-dom";


const AdminFellows = () => {
  usePermissionCheck(Page.FELLOWS);

  const location = useLocation();
  const navigate = useNavigate();
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState(
    location?.search?.length > 0
      ? location?.search?.slice(1)
      : `page=1&page_size=100`
  );


  const {
    data,
    isLoading: fetchingProviders,
    isFetching,
  } = useGetFellowsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      const fullPath = `${location?.pathname}?${filter}`;
      setSkip(false);
      navigate(fullPath);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <AdminLayout>
      <div className="row">
        {fetchingProviders ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <FellowTable
            fellows={data?.data?.results}
            metadata={data?.data?.metadata}
            setFilter={setFilter}
            setSkip={setSkip}
            filtering={fetchingProviders || isFetching}
            filter={filter}
          />
        )}
      </div>
    </AdminLayout>
  );
};
export default AdminFellows;
