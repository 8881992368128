/** @format */

import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { Button } from "react-bootstrap";
import { extractFilterValues } from "utils/functions";
import { COLUMNS } from "./helpers/Columns";
import "./helpers/filtering.css";
import FilterModal from "./helpers/FilterModal";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import { AssignModal } from "./helpers/AssignModal";
import { StatusModal } from "./helpers/StatusModal";
import { CohortModal } from "./helpers/CohortModal";
import { MatchingModal } from "./helpers/MatchingModal";
import useFeatureCheck from "hooks/useFeatureCheck";
import { Checks } from "utils/constant.data";

import ImportModal from "./helpers/ImportModal";
import ExportModal from "./helpers/ExportModal";

import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const FellowTable = ({
  fellows,
  metadata,
  setFilter,
  filter,
  setSkip,
  filtering,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [action, setAction] = useState("");

  const [allowStatus] = useFeatureCheck(Checks.update_fellow_status);
  const [allowCohort] = useFeatureCheck(Checks.reassign_fellow_cohort);
  const [allowReassign] = useFeatureCheck(Checks.update_fellow_profile);
  const [allowExport] = useFeatureCheck(Checks.export_fellows);
  const [allowFellowsUpload] = useFeatureCheck(Checks.can_upload_fellows_data);

  const [selectedFellowIDs, setSelectedFellowIds] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [retrievedFellows, setRetrievedFellows] = useState([]);

  const [exportFilter, setExportFilter] = useState(null);

  const handleRowSelection = useCallback(
    (id, fellow_id) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        setSelectedFellowIds(
          selectedFellowIDs.filter(
            (selectedFellowId) => selectedFellowId !== fellow_id
          )
        );
      } else {
        setSelectedIds([...selectedIds, id]);
        setSelectedFellowIds([...selectedFellowIDs, fellow_id]);
      }
    },
    [selectedIds, selectedFellowIDs]
  );

  const handleSelectAll = () => {
    if (!selectAll) {
      const allIds = fellows.map((_F) => _F?.id);
      const allFellowIds = fellows.map((_F) => _F?.fellow_id);
      setSelectedIds(allIds);
      setSelectedFellowIds(allFellowIds);
    } else {
      setSelectedIds([]);
      setSelectedFellowIds([]);
    }
    setSelectAll(!selectAll);
  };

  const makeFilterString = (values, _page) => {
    const queryString = `page=${_page}&page_size=${metadata?.page_size}${
      values?.status?.length > 0 ? `&status=${values?.status?.join(",")}` : ""
    }${values?.gender?.length > 0 ? `&gender=${values?.gender}` : ""}${
      values?.selectedStates?.length > 0
        ? `&residence=${values?.selectedStates?.join(",")}`
        : ""
    }${
      values?.selectedLGAs?.length > 0
        ? `&lga=${values?.selectedLGAs?.join(",")}`
        : ""
    }${
      values?.selectedCourse?.length > 0
        ? `&course=${values?.selectedCourse?.join(",")}`
        : ""
    }${
      values?.proficiency_level?.length > 0
        ? `&proficiency_level=${values?.proficiency_level?.join(",")}`
        : ""
    }${
      values?.provider_id?.length > 0
        ? `&provider_id=${values?.provider_id}`
        : ""
    }${values?.cohort_id?.length > 0 ? `&cohort_id=${values?.cohort_id}` : ""}${
      values?.training_progress?.length > 0
        ? `&training_progress=${values?.training_progress?.join(",")}`
        : ""
    }`;
    return queryString;
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(_modalFilter);
    const filterString = makeFilterString(values, _page);
    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      setSelectedIds([]);
      setSelectedFellowIds([]);
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  useEffect(() => {
    setData(retrievedFellows);
  }, [retrievedFellows]);

  const handleFellowsExport = async () => {
    if (modalFilter === null) {
      setExportFilter("");
    } else {
      const values = extractFilterValues(modalFilter);
      let payload = `${
        values?.selectedStates?.length > 0
          ? `residence=${values.selectedStates.join(",")}`
          : ""
      }${
        values?.status?.length > 0 ? `&status=${values.status.join(",")}` : ""
      }${
        values?.selectedLGAs?.length > 0
          ? `&lga=${values.selectedLGAs.join(",")}`
          : ""
      }${
        values?.selectedCourse?.length > 0
          ? `&course=${values.selectedCourse.join(",")}`
          : ""
      }${
        values?.cohort_id?.length > 0 ? `&cohort_id=${values?.cohort_id}` : ""
      }${
        values?.proficiency_level?.length > 0
          ? `&proficiency_level=${values?.proficiency_level?.join(",")}`
          : ""
      }${
        values?.provider_id?.length > 0
          ? `&provider_id=${values?.provider_id}`
          : ""
      }${values?.gender?.length > 0 ? `&gender=${values?.gender}` : ""}${
        values?.training_progress?.length > 0
          ? `&training_progress=${values?.training_progress?.join(",")}`
          : ""
      }`;
      setExportFilter(payload);
    }
    setShowExportModal(true);
  };

  useEffect(() => {
    setData([...fellows]);
    setSkip(true);
  }, [fellows, setSkip]);

  useEffect(() => {
    if (filter) {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const columns = useMemo(
    () => COLUMNS(selectedIds, handleRowSelection),
    [selectedIds, handleRowSelection]
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const restoreFellows = () => {
    setClear(!clear);
    setFiltered(false);
    setSearch("");
    setSearched(false);
    setFilter("page=1&page_size=100");
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        restoreFellows={restoreFellows}
        clearFilter={clear}
      />

      <ImportModal
        setShowImportModal={setShowImportModal}
        showModal={showImportModal}
        setRetrievedFellows={setRetrievedFellows}
      />
      <ExportModal
        setShowExportModal={setShowExportModal}
        showModal={showExportModal}
        exportFilter={exportFilter}
      />

      <div className={`card ${filtering ? "disabled" : ""}`}>
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <div className="mr-2">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={() => handleSelectAll()}
              />
            </div>

            <h4 className="card-title">Fellows</h4>
            {selectedIds?.length > 0 && (
              <div className="ml-2">
                <select
                  className="form-control bulk-select"
                  id="action"
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                >
                  <option value="">Choose Bulk Option</option>
                  {allowStatus && (
                    <option value="update_status">Update Fellow Status</option>
                  )}
                  {allowCohort && (
                    <option value="assign_cohort">Assign Cohort</option>
                  )}

                  {allowReassign && (
                    <option value="assign">Assign/Reassign Fellows</option>
                  )}
                  {allowReassign && (
                    <option value="match_fellows">Match fellows</option>
                  )}
                </select>
              </div>
            )}

            {(filtered || searched) && (
              <button
                onClick={() => {
                  setSearch("");
                  setSearched(false);
                  setFiltered(false);
                  setClear(!clear);
                  setFilter(`page=1&page_size=100`);
                  setExportFilter("");
                  setModalFilter(null);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            <input
              className="ml-2 input-search form-control w-100"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by name or email or fellow ID"
            />

            <button
              type="button"
              className="btn btn-outline ms-2 "
              onClick={() => {
                if (search?.length > 0) {
                  setClear(!clear);
                  setFilter(`page=1&search=${search}`);
                  setFiltered(false);
                  setSearched(true);
                }
              }}
            >
              Search
            </button>

            {/* {searched ? (
              <button
                type="button"
                className="btn btn-outline ml"
                onClick={() => {
                  setSearch("");
                  setFilter(`page=1`);
                  setSearched(false);
                }}
              >
                Clear
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline ms-2 "
                onClick={() => {
                  if (search?.length > 0) {
                    setClear(!clear);
                    setFilter(`page=1&search=${search}`);
                    setFiltered(false);
                    setSearched(true);
                  }
                }}
              >
                Search
              </button>
            )} */}

            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>
            {allowFellowsUpload && (
              <button
                type="button"
                className="btn btn-primary ml ms-2"
                onClick={() => {
                  setSkip(true);
                  setShowImportModal(true);
                }}
              >
                Upload
              </button>
            )}

            {allowExport && (
              <Button
                onClick={() => {
                  handleFellowsExport();
                }}
                className="ms-2 pull-right btn-primary"
              >
                Export
              </Button>
            )}
          </div>
        </div>
        {selectedIds?.length > 0 && (
          <div className="card-body">
            <TagsInput value={selectedFellowIDs} disabled={true} />
            <p className="text-warning font-bold m-0">
              {selectedIds?.length} selected
            </p>
          </div>
        )}

        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data.length > 0 ? (
              <>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                      <button
                        className={`btn btn-outline ${
                          hasPrev ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page - 1) || 1;
                          const values = extractFilterValues(modalFilter) ?? {};
                          const modalFilterString = modalFilter
                            ? makeFilterString(values, _newPage)
                            : "";

                          modalFilter
                            ? setFilter(modalFilterString)
                            : setFilter(
                                `page=${_newPage}&page_size=${metadata?.page_size}`
                              );
                        }}
                      >
                        Previous
                      </button>

                      {metadata?.count && (
                        <div className="d-flex">
                          <p className="mb-0">
                            Page <strong>{metadata?.page}</strong> of{" "}
                            <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                            &nbsp;
                          </p>
                          <p className="mb-0">
                            Total <strong>{metadata?.count}</strong>
                          </p>
                        </div>
                      )}

                      <button
                        className={`btn btn-outline ${
                          hasNext ? "" : "disabled"
                        } `}
                        onClick={() => {
                          const _newPage = Number(metadata?.page || 0) + 1;
                          const values = extractFilterValues(modalFilter) ?? {};
                          const modalFilterString = modalFilter
                            ? makeFilterString(values, _newPage)
                            : "";

                          modalFilter
                            ? setFilter(modalFilterString)
                            : setFilter(
                                `page=${_newPage}&page_size=${metadata?.page_size}`
                              );
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>

                <CohortModal
                  action={action}
                  setAction={setAction}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  setSelectedFellowIds={setSelectedFellowIds}
                />

                <AssignModal
                  action={action}
                  setAction={setAction}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  setSelectedFellowIds={setSelectedFellowIds}
                />
                <StatusModal
                  action={action}
                  setAction={setAction}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  setSelectedFellowIds={setSelectedFellowIds}
                />
                <MatchingModal
                  action={action}
                  setAction={setAction}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  setSelectedFellowIds={setSelectedFellowIds}
                />
              </>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    No fellows {filtered ? "for the specified filter" : "yet"}
                  </h4>

                  <button
                    onClick={() => restoreFellows()}
                    className="btn my-2  btn-pigment-green-outline btn-lg px-4 mx-2"
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default FellowTable;
